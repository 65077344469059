import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
// @mui
import { Autocomplete } from '@react-google-maps/api';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Fade,
  MenuItem,
  Portal,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box, fontSize, width } from '@mui/system';
import { useSnackbar } from 'notistack';
// theme
import { useVisibility } from 'src/context/visibility-context';
//
import Iconify from 'src/components/iconify';
import { useLocationFields } from 'src/context/location-context';
import { useJobsData } from 'src/context/job-context';
import axios, { endpoints } from 'src/utils/axios';
import { StyledMenu } from 'src/layouts/main/nav/desktop/styles';
import { useBoolean } from 'src/hooks/use-boolean';
import './style.css';
import { useResponsive } from 'src/hooks/use-responsive';
import { GOOGLE_MAPS_API_KEY } from 'src/config-global';
import { usePositionData } from 'src/context/current-location-context';
import Logo from '../logo';
import CustomPopover from '../custom-popover';
import { DatePicker } from '@mui/x-date-pickers';
import { fDate2 } from 'src/utils/format-time';
// ----------------------------------------------------------------------

export default function HeaderFilters() {
  const { toggleVisibility } = useVisibility();
  const { enqueueSnackbar } = useSnackbar();
  const nav = useBoolean();
  const popover = useBoolean();
  const smDown = useResponsive('down', 'sm');
  const { updatePositionData } = usePositionData();
  const buttonRef = useRef();
  const { updateJobsData } = useJobsData();
  const { locationData, resetLocationData, updateLocationData } = useLocationFields();
  const [positions, setPositions] = useState({});
  const [radius, setRadius] = useState('Select Distance');
  const [loading, setLoading] = useState(false);
  const [formattedAddress, setFormattedAddress] = useState('');
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [resetLoading, setresetLoading] = useState(false);
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');
  const [rangeType, setRangeType] = useState('Select Date Range');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const onLoadAutocomplete = (autocomplete) => {
    setAutocompleteValue(autocomplete);
  };
  useEffect(() => {
    if (locationData.lat) {
      setRadius(locationData.radius || 'Select Distance');
      setPositions({ lat: locationData.lat, lng: locationData.lng });
      setFormattedAddress(locationData.formattedAddress);
    }
  }, [locationData]);
  const menuRef = useRef(null);
  useEffect(() => {
    const handleResize = () => popover.onFalse(); // Close on resize

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          console.log('Latitude:', latitude, 'Longitude:', longitude);

          updatePositionData({
            currentPosition: [latitude, longitude],
          });
          const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}`;

          try {
            const response = await fetch(geocodingUrl);
            const data = await response.json();

            if (data.status === 'OK') {
            } else {
            }
          } catch (error) {}
        },
        (error) => {
          console.error('Error getting location:', error.message);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  const onPlaceChanged = () => {
    if (autocompleteValue !== null) {
      const place = autocompleteValue.getPlace();
      if (place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const newDropOffPosition = { lat, lng };
        setPositions(newDropOffPosition);
        setFormattedAddress(place.formatted_address);
      }
    }
  };

  const handleSubmit = async (min, max, reset) => {
    if (min && max) {
      if (Number(min) > Number(max)) {
        return enqueueSnackbar('Min price must be less than max price', { variant: 'error' });
      }
    }
    if (!reset) {
      updateLocationData({
        lat: positions.lat,
        lng: positions.lng,
        radius: radius == 'Select Distance' ? null : radius,
        formattedAddress,
      });
    }
    const apiData = {};
    if (radius != 'Select Distance') {
      apiData.radiusInMiles = radius;
    }
    if (positions?.lng !== undefined && positions?.lat !== undefined) {
      apiData.coordinates = [positions.lng, positions.lat];
    }
    if (min || max !== '') {
      apiData.price = {};
      if (min) {
        apiData.price.min = Number(min);
      }
      if (max !== '') {
        apiData.price.max = Number(max);
      }
    }

    if (rangeType != 'Select Date Range') {
      apiData.dateFilter = rangeType;
    }

    if (rangeType == 'custom' && !reset) {
      if (startDate == null) {
        return enqueueSnackbar('Please select an start date', { variant: 'error' });
      }
      if (endDate == null) {
        return enqueueSnackbar('Please select an end date', { variant: 'error' });
      }

      const start = startDate;
      const end = endDate;

      start.setHours(0, 0, 0, 0);
      end.setHours(0, 0, 0, 0);

      if (start > end) {
        return enqueueSnackbar('The start date cannot be later than the end date', { variant: 'error' });
      }

      apiData.dateRange = {
        start:startDate,
        end:endDate,
      };
    }
    if(!reset){
      setLoading(true);
    }

    try {
      const { data } = await axios.post(endpoints.getJobs.list, reset ? {} : apiData);
      if (data.data.length > 0) {
        updateJobsData({
          jobs: data.data,
        });
        nav.onFalse();
      } else {
        updateJobsData({
          jobs: [],
        });
        noJobFound();
        enqueueSnackbar('No Job Found', { variant: 'error' });
      }
      setLoading(false);
      setresetLoading(false);
      return data;
    } catch (error) {
      console.error('Error creating compay:', error);
      setLoading(false);
      setresetLoading(false);

      throw error;
    }
  };

  const noJobFound = async () => {
    const apiData = {};

    try {
      const { data } = await axios.post(endpoints.getJobs.list, apiData);
      if (data.data.length > 0) {
        updateJobsData({
          jobs: data.data,
        });
        nav.onFalse();
      }
      return data;
    } catch (error) {
      console.error('Error creating compay:', error);

      throw error;
    }
  };
  const logoPath = '/logo/nav-logo.png';
  const logoPath2 = '/logo/nav-logo-2.png';

  return (
    <>
      <Box
        position="relative"
        sx={{
          margin: '0px 10px',
          textAlign: 'center',
          justifyContent: 'end',
          // paddingLeft: '50px',
          width: '100%',
          flexWrap: { xs: 'wrap', md: 'wrap', lg: 'nowrap' },
          '@media (max-width: 1200px)': {
            // Media query for small screens
            justifyContent: 'end', // Adjust width on small screens
          },
        }}
        gap={3}
        display="flex"
      >
        <Box
          sx={{
            position: 'absolute',
            left: '-20px',
            top: '13px',
            display: 'block',
            '@media (max-width: 1600px)': {
              display: 'none',
            },
            '@media (max-width: 1200px)': {
              display: 'block',
            },
            '@media (max-width: 600px)': {
              top: '5px',
            },
          }}
        >
          {/* <Logo sx={{ height: '30px', width: '150px' }} /> */}
          <img className="nav-logo" height={30} src={logoPath} alt="logo" />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            left: '-20px',
            top: '13px',
            display: 'none',
            '@media (max-width: 1600px)': {
              display: 'block',
            },
            '@media (max-width: 1200px)': {
              display: 'none',
            },
            '@media (max-width: 1350px)': {
              left: '-30px', // Adjust width on small screens
            },
          }}
        >
          <img className="nav-logo" height={30} src={logoPath2} alt="logo" />
        </Box>
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            '@media (max-width: 1200px)': {
              // Media query for small screens
              display: 'none', // Adjust width on small screens
            },
          }}
        >
          <Box
            sx={{
              background: '#4CBB17',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              borderTopLeftRadius: '10px',
              borderBottomLeftRadius: '10px',
              width: '60px',
            }}
          >
            <Iconify style={{ color: 'white' }} icon="gis:location-poi" />
          </Box>
          <Autocomplete
            className="mainContainerAutoComplete"
            onLoad={onLoadAutocomplete}
            onPlaceChanged={onPlaceChanged}
            options={{
              types: ['address'],
              componentRestrictions: { country: 'US' },
            }}
          >
            <TextField
              value={formattedAddress}
              onChange={(e) => setFormattedAddress(e.target.value)}
              placeholder="Enter a location"
              sx={{
                paddingRight: '15px',
                height: '100%',
                width: '400px',
                background: 'white',
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { border: 'none' },
                  '&:hover fieldset': { border: 'none' },
                  '&.Mui-focused fieldset': { border: 'none' },
                },
                '@media (max-width: 1750px)': {
                  // Media query for small screens
                  width: '270px', // Adjust width on small screens
                },
                '@media (max-width: 1650px)': {
                  width: '200px', // Adjust width on small screens
                },
                '@media (max-width: 1350px)': {
                  width: '150px', // Adjust width on small screens
                },
                // '@media (max-width: 1500px)': {
                //   // Media query for small screens
                //   width: '220px', // Adjust width on small screens
                // },
              }}
            />
          </Autocomplete>
          {formattedAddress != '' && (
            <Iconify
              onClick={() => {
                setFormattedAddress('');
              }}
              icon="material-symbols:close"
              style={{
                position: 'absolute',
                top: '16px',
                right: '10px',
                color: '#9ea392',
                cursor: 'pointer',
                cursor: 'pointer',
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            '@media (max-width: 1200px)': {
              // Media query for small screens
              display: 'none', // Adjust width on small screens
            },
          }}
        >
          <Box
            sx={{
              background: '#4CBB17',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              borderTopLeftRadius: '10px',
              borderBottomLeftRadius: '10px',
              width: '60px',
              color: 'white',
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12 10a2 2 0 0 1 2 2a2 2 0 0 1-.47 1.29L16.7 22h-2.13L12 14.93L9.43 22H7.3l3.17-8.71A2 2 0 0 1 10 12a2 2 0 0 1 2-2m0-2a4 4 0 0 0-4 4c0 .5.1 1 .28 1.46l-.88 2.4A6.03 6.03 0 0 1 6 12a6 6 0 0 1 6-6a6 6 0 0 1 6 6c0 1.47-.53 2.81-1.4 3.86l-.88-2.4C15.9 13 16 12.5 16 12a4 4 0 0 0-4-4m0-4a8 8 0 0 0-8 8c0 2.36 1 4.5 2.64 5.94l-.72 2A10 10 0 0 1 2 12A10 10 0 0 1 12 2a10 10 0 0 1 10 10c0 3.23-1.54 6.11-3.92 7.94l-.72-2C19 16.5 20 14.36 20 12a8 8 0 0 0-8-8"
              />
            </svg>
          </Box>
          <TextField
            select
            fullWidth
            value={radius}
            className={radius == 'Select Distance' ? 'select-placeholder' : ''}
            onChange={(e) => setRadius(e.target.value)}
            sx={{
              width: '130px',
              borderTopRightRadius: '10px',
              borderBottomRightRadius: '10px',
              background: 'white',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none', // No border normally
                },
                '&.Mui-focused fieldset': {
                  border: 'none', // No border on focus
                },
              },
              '@media (max-width: 1500px)': {
                // Media query for small screens
                width: '100px', // Adjust width on small screens
              },
            }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    maxHeight: 220,
                    textTransform: 'capitalize',
                  },
                },
              },
              sx: { textTransform: 'capitalize' },
            }}
          >
            <MenuItem value="Select Distance" disabled>
              Select Distance
            </MenuItem>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box position="relative">
          <Button
            ref={buttonRef}
            onClick={popover.onToggle}
            color="primary"
            size={smDown ? 'medium' : 'large'}
            variant="contained"
            startIcon={<Iconify size={30} icon="material-symbols:filter-alt-sharp" />}
            sx={{
              marginTop: '3px',
              '@media (max-width: 1200px)': {
                // Media query for small screens
                display: 'none', // Adjust width on small screens
              },
            }}
          >
            Filter By
          </Button>
          {popover.value && (
            <Portal>
              <Fade in={popover.onTrue}>
                <StyledMenu
                  ref={menuRef}
                  sx={{
                    display: 'flex',
                    maxWidth: '500px',
                    margin: '0px',
                    position: 'absolute',
                    top: buttonRef.current.getBoundingClientRect().bottom + window.scrollY + 10, // 10px gap
                    left: buttonRef.current.getBoundingClientRect().left,
                    '@media (max-width: 1200px)': {
                      // Media query for small screens
                      display: 'none', // Adjust width on small screens
                    },
                  }}
                >
                  <Box
                    gap={2}
                    display="grid"
                    gridTemplateColumns={{
                      xs: 'repeat(1, 1fr)',
                      lg: 'repeat(1, 1fr)',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        position: 'relative',
                        borderRadius: '10px',
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                        width: '100%',
                        '@media (max-width: 1200px)': {
                          // Media query for small screens
                          display: 'none', // Adjust width on small screens
                        },
                      }}
                    >
                      <Box
                        sx={{
                          background: '#4CBB17',
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'center',
                          borderTopLeftRadius: '10px',
                          borderBottomLeftRadius: '10px',
                          width: '70px',
                        }}
                      >
                        <Typography color="white">Min</Typography>
                        {/* <Iconify style={{ color: 'white' }} icon="ph:sliders-duotone" /> */}
                      </Box>
                      <TextField
                        value={min}
                        onChange={(e) => setMin(e.target.value)}
                        type="number"
                        placeholder="Min Price"
                        sx={{
                          height: '100%',
                          paddingRight: '15px',
                          width: '100%',
                          background: 'white',
                          borderTopRightRadius: '10px',
                          borderBottomRightRadius: '10px',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': { border: 'none' },
                            '&:hover fieldset': { border: 'none' },
                            '&.Mui-focused fieldset': { border: 'none' },
                          },
                        }}
                      />
                      {min != '' && (
                        <Iconify
                          onClick={() => {
                            setMin('');
                            // handleSubmit('', max);
                          }}
                          icon="material-symbols:close"
                          style={{
                            position: 'absolute',
                            top: '16px',
                            right: '10px',
                            color: '#9ea392',
                            cursor: 'pointer',
                            cursor: 'pointer',
                          }}
                        />
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        position: 'relative',
                        borderRadius: '10px',
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                        '@media (max-width: 1200px)': {
                          // Media query for small screens
                          display: 'none', // Adjust width on small screens
                        },
                      }}
                    >
                      <Box
                        sx={{
                          background: '#4CBB17',
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'center',
                          borderTopLeftRadius: '10px',
                          borderBottomLeftRadius: '10px',
                          width: '60px',
                        }}
                      >
                        <Typography color="white">Max</Typography>
                        {/* <Iconify style={{ color: 'white' }} icon="ph:sliders-duotone" /> */}
                      </Box>
                      <TextField
                        value={max}
                        onChange={(e) => setMax(e.target.value)}
                        type="number"
                        placeholder="Max Price"
                        sx={{
                          height: '100%',
                          width: '400px',
                          background: 'white',
                          borderTopRightRadius: '10px',
                          borderBottomRightRadius: '10px',
                          paddingRight: '15px',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': { border: 'none' },
                            '&:hover fieldset': { border: 'none' },
                            '&.Mui-focused fieldset': { border: 'none' },
                          },
                        }}
                      />
                      {max != '' && (
                        <Iconify
                          onClick={() => {
                            setMax('');
                            // handleSubmit(min, '');
                          }}
                          icon="material-symbols:close"
                          style={{
                            position: 'absolute',
                            top: '16px',
                            right: '10px',
                            color: '#9ea392',
                            cursor: 'pointer',
                          }}
                        />
                      )}
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        borderRadius: '10px',
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                        '@media (max-width: 1200px)': {
                          display: 'none', // Hide on small screens
                        },
                      }}
                      mb={rangeType != 'Custom' ? 2 : 0}
                    >
                      <Box
                        sx={{
                          background: '#4CBB17',
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'center',
                          borderTopLeftRadius: '10px',
                          borderBottomLeftRadius: '10px',
                          width: '70px',
                          color: 'white',
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M8 14q-.425 0-.712-.288T7 13t.288-.712T8 12t.713.288T9 13t-.288.713T8 14m4 0q-.425 0-.712-.288T11 13t.288-.712T12 12t.713.288T13 13t-.288.713T12 14m4 0q-.425 0-.712-.288T15 13t.288-.712T16 12t.713.288T17 13t-.288.713T16 14M5 22q-.825 0-1.412-.587T3 20V6q0-.825.588-1.412T5 4h1V3q0-.425.288-.712T7 2t.713.288T8 3v1h8V3q0-.425.288-.712T17 2t.713.288T18 3v1h1q.825 0 1.413.588T21 6v14q0 .825-.587 1.413T19 22zm0-2h14V10H5zM5 8h14V6H5zm0 0V6z"
                          />
                        </svg>
                      </Box>

                      <TextField
                        select
                        fullWidth
                        value={rangeType}
                        className={rangeType === 'Select Date Range' ? 'select-placeholder' : ''}
                        onChange={(e) => {
                          if (e.target.value != 'custom') {
                            setStartDate(null);
                            setEndDate(null);
                          }
                          setRangeType(e.target.value);
                        }}
                        sx={{
                          width: '100%',
                          borderTopRightRadius: '10px',
                          borderBottomRightRadius: '10px',
                          background: 'white',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': { border: 'none' },
                            '&.Mui-focused fieldset': { border: 'none' },
                          },
                        }}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              sx: {
                                maxHeight: 220,
                                textTransform: 'capitalize',
                              },
                            },
                          },
                          sx: { textTransform: 'capitalize' },
                        }}
                      >
                        <MenuItem value="Select Date Range">Select Date Range</MenuItem>
                        {[
                          { label: 'Past 30 days', value: 'last30days' },
                          { label: 'Past Year', value: 'lastYear' },
                          { label: 'Past 5 Years', value: 'last5Years' },
                          { label: 'Custom', value: 'custom' },
                        ].map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>

                    {rangeType === 'custom' && (
                      <Box
                        mb={2}
                        gap={2}
                        display="grid"
                        gridTemplateColumns={{
                          xs: 'repeat(2, 1fr)',
                          lg: 'repeat(2, 1fr)',
                        }}
                      >
                        <DatePicker
                          value={startDate}
                          onChange={(e) => setStartDate(e)}
                          sx={{
                            borderRadius: '10px',
                            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                          }}
                          label="Start date"
                          slotProps={{
                            textField: { fullWidth: true },
                          }}
                        />
                        <DatePicker
                          value={endDate}
                          onChange={(e) => setEndDate(e)}
                          sx={{
                            borderRadius: '10px',
                            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                          }}
                          label="End date"
                          slotProps={{
                            textField: { fullWidth: true },
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </StyledMenu>
              </Fade>
            </Portal>
          )}
        </Box>

        <LoadingButton
          loading={loading}
          onClick={() => {
            handleSubmit(min, max);
          }}
          sx={{
            width: '150px',
            marginTop: '3px',
            '@media (max-width: 1500px)': {
              // Media query for small screens
              width: '120px', // Adjust width on small screens
            },
            '@media (max-width: 1200px)': {
              // Media query for small screens
              display: 'none', // Adjust width on small screens
            },
          }}
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          startIcon={<Iconify icon="material-symbols:search" />}
        >
          Search
        </LoadingButton>
        <Tooltip title="Reset">
          <LoadingButton
            loading={resetLoading}
            onClick={() => {
              setPositions({});
              setRadius('Select Distance');
              setFormattedAddress('');
              setMax('');
              setMin('');
              resetLocationData();
              setresetLoading(true);
              setRangeType('Select Date Range');
              setStartDate(null);
              setEndDate(null);
              handleSubmit('', '', true);
            }}
            color="primary"
            size="large"
            variant="contained"
            sx={{
              marginTop: '3px',
              '@media (max-width: 1200px)': {
                // Media query for small screens
                display: 'none', // Adjust width on small screens
              },
            }}
          >
            <Iconify icon="material-symbols:refresh" />
          </LoadingButton>
        </Tooltip>
        <Box>
          <Button
            onClick={() => nav.onToggle()}
            color="primary"
            size={smDown ? 'medium' : 'large'}
            variant="contained"
            sx={{
              marginTop: '3px',
              '@media (min-width: 1200px)': {
                // Media query for small screens
                display: 'none', // Adjust width on small screens
              },
            }}
          >
            <Iconify size={30} icon="material-symbols:filter-alt-sharp" />
          </Button>
          <Tooltip title="List View">
            <Button
              onClick={() => {
                nav.onFalse();
                toggleVisibility();
              }}
              color="primary"
              size={smDown ? 'medium' : 'large'}
              variant="contained"
              sx={{ marginTop: '3px', marginLeft: '10px' }}
            >
              <Iconify icon="ic:outline-menu" />
            </Button>
          </Tooltip>
        </Box>
      </Box>

      {/* for mobile view filters */}
      {nav.value && (
        <Portal>
          <Fade in={nav.onTrue}>
            <StyledMenu
              sx={{
                display: 'flex',
                maxWidth: '500px',
                top: '70px',
                margin: '0px',
                left: '20px',
                right: '20px',

                '@media (min-width: 1200px)': {
                  // Media query for small screens
                  display: 'none', // Adjust width on small screens
                },
              }}
            >
              <Box
                sx={{
                  margin: '0px 10px 30px',
                  // textAlign: 'center',
                  // justifyContent: 'center',
                  width: '100%',
                  // flexWrap: { xs: 'wrap', md: 'wrap', lg: 'nowrap' }
                }}
                gap={3}
                // display="flex"
              >
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    width: '100%',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    borderRadius: '10px',
                  }}
                >
                  <Box
                    sx={{
                      background: '#4CBB17',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: '10px',
                      width: '60px',
                    }}
                  >
                    <Iconify style={{ color: 'white' }} icon="gis:location-poi" />
                  </Box>
                  <Autocomplete
                    className="mainContainerAutoComplete2"
                    onLoad={onLoadAutocomplete}
                    onPlaceChanged={onPlaceChanged}
                  >
                    <TextField
                      value={formattedAddress}
                      onChange={(e) => setFormattedAddress(e.target.value)}
                      placeholder="Enter a location"
                      size="small"
                      sx={{
                        paddingRight: '15px',
                        height: '100%',
                        width: '100%',
                        paddingRight: '15px',
                        background: 'white',
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '10px',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': { border: 'none' },
                          '&:hover fieldset': { border: 'none' },
                          '&.Mui-focused fieldset': { border: 'none' },
                        },
                      }}
                    />
                  </Autocomplete>
                  {formattedAddress != '' && (
                    <Iconify
                      onClick={() => {
                        setFormattedAddress('');
                      }}
                      icon="material-symbols:close"
                      style={{
                        position: 'absolute',
                        top: '9px',
                        right: '10px',
                        color: '#9ea392',
                        cursor: 'pointer',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    marginTop: '10px',
                    width: '100%',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    borderRadius: '10px',
                  }}
                >
                  <Box
                    sx={{
                      background: '#4CBB17',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: '10px',
                      width: '60px',

                      color: 'white',
                    }}
                  >
                    {/* <Iconify style={{ color: 'white' }} icon="tabler:location-filled" /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M12 10a2 2 0 0 1 2 2a2 2 0 0 1-.47 1.29L16.7 22h-2.13L12 14.93L9.43 22H7.3l3.17-8.71A2 2 0 0 1 10 12a2 2 0 0 1 2-2m0-2a4 4 0 0 0-4 4c0 .5.1 1 .28 1.46l-.88 2.4A6.03 6.03 0 0 1 6 12a6 6 0 0 1 6-6a6 6 0 0 1 6 6c0 1.47-.53 2.81-1.4 3.86l-.88-2.4C15.9 13 16 12.5 16 12a4 4 0 0 0-4-4m0-4a8 8 0 0 0-8 8c0 2.36 1 4.5 2.64 5.94l-.72 2A10 10 0 0 1 2 12A10 10 0 0 1 12 2a10 10 0 0 1 10 10c0 3.23-1.54 6.11-3.92 7.94l-.72-2C19 16.5 20 14.36 20 12a8 8 0 0 0-8-8"
                      />
                    </svg>
                  </Box>
                  <TextField
                    size="small"
                    select
                    fullWidth
                    value={radius}
                    onChange={(e) => setRadius(e.target.value)}
                    className={radius == 'Select Distance' ? 'select-placeholder' : ''}
                    sx={{
                      width: '100%',
                      borderTopRightRadius: '10px',
                      borderBottomRightRadius: '10px',
                      background: 'white',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none', // No border normally
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none', // No border on focus
                        },
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          sx: {
                            maxHeight: 220,
                            textTransform: 'capitalize',
                          },
                        },
                      },
                      sx: { textTransform: 'capitalize' },
                    }}
                  >
                    <MenuItem value="Select Distance" disabled>
                      Select Distance
                    </MenuItem>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    position: 'relative',
                    marginTop: '10px',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    borderRadius: '10px',
                  }}
                >
                  <Box
                    sx={{
                      background: '#4CBB17',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: '10px',
                      width: '60px',
                    }}
                  >
                    <Typography color="white">Min</Typography>
                    {/* <Iconify style={{ color: 'white' }} icon="ph:sliders-duotone" /> */}
                  </Box>
                  <TextField
                    value={min}
                    onChange={(e) => setMin(e.target.value)}
                    type="number"
                    placeholder="Min Price"
                    size="small"
                    sx={{
                      height: '100%',
                      width: '100%',
                      background: 'white',
                      borderTopRightRadius: '10px',
                      borderBottomRightRadius: '10px',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': { border: 'none' },
                        '&:hover fieldset': { border: 'none' },
                        '&.Mui-focused fieldset': { border: 'none' },
                      },
                    }}
                  />
                  {min != '' && (
                    <Iconify
                      onClick={() => {
                        setMin('');
                        // handleSubmit('', max);
                      }}
                      icon="material-symbols:close"
                      style={{
                        position: 'absolute',
                        top: '9px',
                        right: '10px',
                        color: '#9ea392',
                        cursor: 'pointer',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    position: 'relative',
                    marginTop: '10px',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    borderRadius: '10px',
                  }}
                >
                  <Box
                    sx={{
                      background: '#4CBB17',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      borderTopLeftRadius: '10px',
                      width: '60px',
                      borderBottomLeftRadius: '10px',
                    }}
                  >
                    <Typography color="white">Max</Typography>
                    {/* <Iconify style={{ color: 'white' }} icon="ph:sliders-duotone" /> */}
                  </Box>
                  <TextField
                    value={max}
                    onChange={(e) => setMax(e.target.value)}
                    type="number"
                    size="small"
                    placeholder="Max Price"
                    sx={{
                      height: '100%',
                      width: '100%',
                      background: 'white',
                      borderTopRightRadius: '10px',
                      borderBottomRightRadius: '10px',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': { border: 'none' },
                        '&:hover fieldset': { border: 'none' },
                        '&.Mui-focused fieldset': { border: 'none' },
                      },
                    }}
                  />
                  {max != '' && (
                    <Iconify
                      onClick={() => {
                        setMax('');
                        // handleSubmit(min, '');
                      }}
                      icon="material-symbols:close"
                      style={{
                        position: 'absolute',
                        top: '9px',
                        right: '10px',
                        color: '#9ea392',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    borderRadius: '10px',
                    marginTop: '10px',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                  }}
                >
                  <Box
                    sx={{
                      background: '#4CBB17',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: '10px',
                      width: '60px',
                      color: 'white',
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M8 14q-.425 0-.712-.288T7 13t.288-.712T8 12t.713.288T9 13t-.288.713T8 14m4 0q-.425 0-.712-.288T11 13t.288-.712T12 12t.713.288T13 13t-.288.713T12 14m4 0q-.425 0-.712-.288T15 13t.288-.712T16 12t.713.288T17 13t-.288.713T16 14M5 22q-.825 0-1.412-.587T3 20V6q0-.825.588-1.412T5 4h1V3q0-.425.288-.712T7 2t.713.288T8 3v1h8V3q0-.425.288-.712T17 2t.713.288T18 3v1h1q.825 0 1.413.588T21 6v14q0 .825-.587 1.413T19 22zm0-2h14V10H5zM5 8h14V6H5zm0 0V6z"
                      />
                    </svg>
                  </Box>

                  <TextField
                    size="small"
                    select
                    fullWidth
                    value={rangeType}
                    className={rangeType === 'Select Date Range' ? 'select-placeholder' : ''}
                    onChange={(e) => {
                      if (e.target.value != 'custom') {
                        setStartDate(null);
                        setEndDate(null);
                      }
                      setRangeType(e.target.value);
                    }}
                    sx={{
                      width: '100%',
                      borderTopRightRadius: '10px',
                      borderBottomRightRadius: '10px',
                      background: 'white',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': { border: 'none' },
                        '&.Mui-focused fieldset': { border: 'none' },
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          sx: {
                            maxHeight: 220,
                            textTransform: 'capitalize',
                          },
                        },
                      },
                      sx: { textTransform: 'capitalize' },
                    }}
                  >
                    <MenuItem value="Select Date Range">Select Date Range</MenuItem>
                    {[
                      { label: 'Past 30 days', value: 'last30days' },
                      { label: 'Past Year', value: 'lastYear' },
                      { label: 'Past 5 Years', value: 'last5Years' },
                      { label: 'Custom', value: 'custom' },
                    ].map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>

                {rangeType === 'custom' && (
                  <Box
                    gap={2}
                    display="grid"
                    gridTemplateColumns={{
                      xs: 'repeat(1, 1fr)',
                      lg: 'repeat(1, 1fr)',
                    }}
                  >
                    <DatePicker
                      value={startDate}
                      onChange={(e) => setStartDate(e)}
                      sx={{
                        marginTop: '10px',
                        borderRadius: '10px',
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                      }}
                      label="Start date"
                      slotProps={{
                        textField: { fullWidth: true, size: 'small' },
                      }}
                    />
                    <DatePicker
                      value={endDate}
                      onChange={(e) => setEndDate(e)}
                      sx={{
                        borderRadius: '10px',
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                      }}
                      label="End date"
                      slotProps={{
                        textField: { fullWidth: true, size: 'small' },
                      }}
                    />
                  </Box>
                )}
                <Box display="flex" gap={2}>
                  <LoadingButton
                    loading={loading}
                    onClick={() => {
                      handleSubmit(min, max);
                    }}
                    sx={{ width: '100%', marginTop: '3px', marginTop: '10px' }}
                    color="primary"
                    size="medium"
                    type="submit"
                    variant="contained"
                    startIcon={<Iconify icon="material-symbols:search" />}
                  >
                    Search
                  </LoadingButton>
                  <Tooltip title="Reset">
                    <LoadingButton
                      size="medium"
                      loading={resetLoading}
                      onClick={() => {
                        setPositions({});
                        setRadius('Select Distance');
                        setFormattedAddress('');
                        setMax('');
                        setMin('');
                        resetLocationData();
                        setresetLoading(true);
                        setRangeType('Select Date Range');
                        setStartDate(null);
                        setEndDate(null);
                        handleSubmit('', '', true);
                      }}
                      color="primary"
                      variant="contained"
                      sx={{ marginTop: '10px' }}
                    >
                      <Iconify icon="material-symbols:refresh" />
                    </LoadingButton>
                  </Tooltip>
                </Box>
              </Box>
            </StyledMenu>
          </Fade>
        </Portal>
      )}
    </>
  );
}
