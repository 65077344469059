import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Autocomplete } from '@react-google-maps/api';
import AppBar from '@mui/material/AppBar';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { LoadingButton } from '@mui/lab';
import { Button, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { Box, fontSize } from '@mui/system';
// theme
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
import { useVisibility } from 'src/context/visibility-context';
import HeaderFilters from 'src/components/header-filters/header-filter';
//
import Iconify from 'src/components/iconify';
import { HEADER, NAV } from '../config-layout';
import './style.css';
import { AccountPopover } from '../_common';
// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
  const theme = useTheme();

  const settings = useSettingsContext();
  const { toggleVisibility } = useVisibility();
  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const onLoadAutocomplete = (autocomplete) => {
    setAutocompleteValue(autocomplete);
  };
  const onPlaceChanged = () => {
    if (autocompleteValue !== null) {
      const place = autocompleteValue.getPlace();
      if (place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const newDropOffPosition = { lat, lng };
        console.log(newDropOffPosition);
      }
    }
  };

  const renderContent = (
    <>
      {/* {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      <Searchbar />

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        <LanguagePopover />

        <NotificationsPopover />

        <ContactsPopover />

        <SettingsButton />

        <AccountPopover />
      </Stack> */}
      {/* <Stack
        spacing={2.5}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      > */}
      <HeaderFilters />
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        <AccountPopover />
      </Stack>
      {/* </Stack> */}
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        // opacity: 0.5,
        background: 'rgba(13, 1, 0, 0.3)',
        // ...bgBlur({
        //   color: '#333',
        // }),
        // transition: theme.transitions.create(['height'], {
        //   duration: theme.transitions.duration.shorter,
        // }),
        ...(lgUp && {
          // width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
