import React, { createContext, useState, useContext, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

const CurrentPositionContext = createContext();

export const CurrentPositionProvider = ({ children }) => {
  const initialPositionData = useMemo(
    () => ({
      currentPosition: [],
    }),
    []
  );

  // Retrieve positionData from localStorage or use default
  const getInitialData = () => {
    const storedData = localStorage.getItem('positionData');
    return storedData ? JSON.parse(storedData) : initialPositionData;
  };

  const [positionData, setPositionData] = useState(getInitialData);

  // Update localStorage whenever positionData changes
  useEffect(() => {
    localStorage.setItem('positionData', JSON.stringify(positionData));
  }, [positionData]);

  const updatePositionData = (data) => {
    setPositionData(data);
  };

  const resetPositionData = useCallback(() => {
    setPositionData(initialPositionData);
    localStorage.removeItem('positionData');
  }, [initialPositionData]);

  const contextValue = useMemo(
    () => ({
      positionData,
      updatePositionData,
      resetPositionData,
    }),
    [positionData, resetPositionData]
  );

  return (
    <CurrentPositionContext.Provider value={contextValue}>
      {children}
    </CurrentPositionContext.Provider>
  );
};

CurrentPositionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const usePositionData = () => useContext(CurrentPositionContext);
