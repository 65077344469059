import React, { createContext, useState, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

const JobsContext = createContext();

export const JobsProvider = ({ children }) => {
  const initialJobsData = useMemo(
    () => ({
      jobs: [],
    }),
    []
  );

  const [JobsData, setJobsData] = useState(initialJobsData);

  const updateJobsData = (data) => {
    setJobsData(data);
  };

  const resetJobsData = useCallback(() => {
    setJobsData(initialJobsData);
  }, [initialJobsData]);

  const contextValue = useMemo(
    () => ({
      JobsData,
      updateJobsData,
      resetJobsData,
    }),
    [JobsData, resetJobsData]
  );

  return <JobsContext.Provider value={contextValue}>{children}</JobsContext.Provider>;
};

JobsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useJobsData = () => useContext(JobsContext);
