import React, { createContext, useState, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const initialLocationData = useMemo(
    () => ({
      lat: null,
      lng: null,
      radius: null,
      formattedAddress: '',
    }),
    []
  );

  const [locationData, setLocationData] = useState(initialLocationData);

  const updateLocationData = (data) => {
    setLocationData(data);
  };

  const resetLocationData = useCallback(() => {
    setLocationData(initialLocationData);
  }, [initialLocationData]);

  const contextValue = useMemo(
    () => ({
      locationData,
      updateLocationData,
      resetLocationData,
    }),
    [locationData, resetLocationData]
  );

  return <LocationContext.Provider value={contextValue}>{children}</LocationContext.Provider>;
};

LocationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useLocationFields = () => useContext(LocationContext);
