import React, { createContext, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

// Create the context
const VisibilityContext = createContext();

// Create a custom hook to use the context
export const useVisibility = () => useContext(VisibilityContext);
// Provider component
export const VisibilityProvider = ({ children }) => {
  const [showMapAndList, setShowMapAndList] = useState(false);

  const toggleVisibility = () => setShowMapAndList((prevState) => !prevState);

  // Memoize the context value to avoid unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      showMapAndList,
      toggleVisibility,
    }),
    [showMapAndList]
  );

  return <VisibilityContext.Provider value={contextValue}>{children}</VisibilityContext.Provider>;
};

// PropTypes validation for the VisibilityProvider component
VisibilityProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
